import { render, staticRenderFns } from "./SubNavigation.vue?vue&type=template&id=c6dfb050&scoped=true&"
import script from "./SubNavigation.vue?vue&type=script&lang=js&"
export * from "./SubNavigation.vue?vue&type=script&lang=js&"
import style0 from "./SubNavigation.vue?vue&type=style&index=0&id=c6dfb050&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6dfb050",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
installComponents(component, {VIcon,VList,VListItem})
