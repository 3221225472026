<template>
  <div id='settings'>
    <SubNavigation
      :items='navItemsWithPermission'
      class='ma-4'
    />
  </div>
</template>

<style lang="scss">
#settings{
  height: 100%;
}
</style>


<script>
import { mapGetters, mapActions } from 'vuex';
import SubNavigation from '@/components/core/SubNavigation';
export default {
  name: 'Settings',
  components: {
    SubNavigation,
  },
  data(){ return {
    navItems: [
      { label: "About", icon: "mdi-information", link: "/settings/about", perm: null },
      { label: "Authentication", icon: "mdi-lock", link: "/settings/authentication" },
      { label: "Users", icon: "mdi-account-circle", link: "/settings/users", perm: "USERS" },
      { label: "Stages", icon: "mdi-chart-gantt", link: "/settings/stages", perm: "STAGES" },
      { label: "Billing States", icon: "mdi-cash-register", link: "/settings/billing_stages", perm: "BILLING_STAGES" },
      { label: "Measure Options", icon: "mdi-tape-measure", link: "/settings/measure_options", perm: "SETTINGS_MEASURE" },
      { label: "Schedule", icon: "mdi-chart-timeline", link: "/settings/schedule", perm: "SETTINGS_SCHEDULE" },
      { label: "Customers", icon: "mdi-account-group", link: "/settings/customers", perm: "SETTINGS_CUSTOMER" },
    ],
  }},
  computed: {
    ...mapGetters("AUTH", ['PERMS']),
    navItemsWithPermission(){
      return this.navItems.filter(item => !item.perm || this.PERMS.includes(item.perm));
    },
  },
  watch:{
    $route: { handler(){ let t = `Settings`; this.SET_TITLE(t); }, immediate: true },
  },
  methods: {
    ...mapActions("UI", ['SET_TITLE']),
  },
}
</script>
