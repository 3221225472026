<template>
  <div id="subNav" class='xfill-height' :class='mode'>

    <div id="menu" class='ma-4 rounded-lg white elevation-2'>
      <v-list id='nav' class='my-0 mx-2 transparent'>
        <v-list-item
          v-for='item in items' v-bind:key='item.page'
          :class='{active: isMatch(item.link)}'
          @click='navigate(item.link)'
          class='navItem rounded my-1 py-2'
          cols=12
        >
          <v-icon class='mx-5' :class='item.icon'>{{item.icon}}</v-icon>
          <p class='mb-0'>{{item.label}}<br /></p>
        </v-list-item>
      </v-list>
    </div>

    <div id="content" class=''>
      <router-view class='' />
    </div>


  </div>
</template>

<style scoped lang="scss">
#subNav{
  &.side{
    xdisplay: flex;
    height: 100%;
    #menu{
      width: 250px;
      display: block;
      float:left;
      max-height: calc(100% - 25px);
    }
    #content{
      flex-grow: 1;
      height: 100%;
    }
  }
  &.stack{
    height: 100%;
    overflow-y: auto;
    #menu{
      max-height: 50%;
      width: 250px;
      max-width: 70%;
      background-color: white;
      margin: 16px !important;
    }
    #content{
      margin: 5px !important;
      height: calc(100% - 50px);
    }
  }
}
#menu{
  .navItem{
    cursor: pointer;
    .v-icon{ color: #114; }
    min-height: 0px;
    &:hover{
      background-color: #1143;
    }
    &.active{
      color: white;
      .v-icon{ color: white; }
      background-color: #18E;
    }
  }
}
#content, #menu{
  overflow-y: auto;
}
</style>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'SubNavigation',
  components: {},
  props: {
    items: { type: Array, required: true },
  },
  data(){
    return {}
  },
  computed: {
    page(){ return this.$route.path.split('/')[2] ?? null; },
    subPage(){ return this.$route.path.split('/')[3] ?? null; },
    mode(){ return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name) ? "stack" : "side"; }
  },
  methods: {
    ...mapActions("UI", ['NAVIGATE']),
    isMatch(link){
      let page = link.split('/')[1] ?? null;
      let subPage = link.split('/')[2] ?? null;
      return page == this.page && subPage == this.subPage;
    },
    navigate(route){
      route = '/dash' + route;
      if(route != this.$route.path) this.NAVIGATE(route);
    }
  },
  created(){},
  mounted(){
    if(!this.subPage) this.navigate(this.items[0].link);
  }
}
</script>
