var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "xfill-height",
    class: _vm.mode,
    attrs: {
      "id": "subNav"
    }
  }, [_c('div', {
    staticClass: "ma-4 rounded-lg white elevation-2",
    attrs: {
      "id": "menu"
    }
  }, [_c('v-list', {
    staticClass: "my-0 mx-2 transparent",
    attrs: {
      "id": "nav"
    }
  }, _vm._l(_vm.items, function (item) {
    return _c('v-list-item', {
      key: item.page,
      staticClass: "navItem rounded my-1 py-2",
      class: {
        active: _vm.isMatch(item.link)
      },
      attrs: {
        "cols": "12"
      },
      on: {
        "click": function click($event) {
          return _vm.navigate(item.link);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mx-5",
      class: item.icon
    }, [_vm._v(_vm._s(item.icon))]), _c('p', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(item.label)), _c('br')])], 1);
  }), 1)], 1), _c('div', {
    attrs: {
      "id": "content"
    }
  }, [_c('router-view', {})], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }